<template lang="pug">
#arc-gauge.h-100(v-if="dataLoaded" style="margin-top:-1rem") 
    ejs-circulargauge(:ref='"circulargauge_"+name' :id='"range-container_"+name' :width="height+'px'" :height="height+'px'" :background='background' style='display:block' align='center' :loaded='loaded' title='' titleStyle='')
        template(v-slot:indicatorTemplate='{}')
            #pointervalue(style='font-size:14px; font-weight:600; width:80px;text-align:center') {{data.percent}}%
        e-axes
            e-axis(:radius='gaugeRadius' :minimum='0' :maximum='100' :startAngle='startAngle' :endAngle='endAngle' :majorTicks='majorTicks' :lineStyle='lineStyle' :minorTicks='minorTicks' :labelStyle='labelStyle' :ranges='ranges')
                e-annotations
                    e-annotation(:content="'indicatorTemplate'" angle='0' zindex='1' radius='0%')
                e-pointers
                    e-pointer(:roundedCornerRadius='8' :value='data.percent' :radius='pointerRadius' :color='color' :type='type' :animation='animation' :border='border' :pointerWidth='8' :cap='cap' :needleTail='needleTail')
</template>
<script>
    import { CircularGaugeComponent, AxesDirective, AxisDirective, PointersDirective, PointerDirective, AnnotationsDirective, AnnotationDirective, Annotations } from "@syncfusion/ej2-vue-circulargauge";
    import { SliderComponent, SliderChangeEventArgs, Slider } from "@syncfusion/ej2-vue-inputs";
    
    export default {
        components: {
            'ejs-circulargauge': CircularGaugeComponent,
            'e-axes': AxesDirective,
            'e-axis': AxisDirective,
            'e-pointers': PointersDirective,
            'e-pointer': PointerDirective,
            'e-annotations': AnnotationsDirective,
            'e-annotation': AnnotationDirective,
            'ejs-slider': SliderComponent
        },
        provide: {
            circulargauge: [Annotations]
        },
        props:{
            data:{
                type:Object,
                default: {
                    percent : 0
                }
            },
            name:{
                type:String,
                default:"circularGaugeActivities",
            },
            height:{
                type:Number,
                default:100,
            },
            viewMode:{
                type:String,
                default:"compare"
            }
        },
        data() {
            return {
                title: "",
                dataLoaded:false,
    
                gaugeRadius: '100%',
                background:'transparent',
                startAngle: 200,
                endAngle: 160,
                majorTicks: {
                    width: 0
                },
                lineStyle:  { width: 0 },
                minorTicks: {
                    width: 0
                },
                labelStyle: {
                     position: 'Inside', useRangeColor: true,
                     font: { size: '0px', fontFamily: 'inherit'}
                },
                value: 90,
                pointerRadius: '100%',
                color:  this.data.percent > 0 && this.data.percent <= 20 ? '#ea501a' :  this.data.percent <= 40 ? '#f79c02' : this.data.percent <= 60 ? '#e5ce20' : this.data.percent <= 80 ? '#a1cb43' : '#82b944'  , //'#e5ce20'
                sliderValue: this.data.percent,
                ranges: [
                        {
                            start: 0, end: 100,
                            radius: '100%',
                            startWidth: 8, endWidth: 8,
                            color: '#E0E0E0',
                            roundedCornerRadius: 8
                        },
                ],
                cap: {
                       radius: 10,
                       color:'#757575'
                },
                needleTail: {
                        length: '18%',
                        color:'#757575'
                },
                type: 'RangeBar',
                border: {                       
                        width: 0
                },
                animation: {
                        enable: false
                }
            }
        },
        watch : {
            data: function (newVal, oldVal) {
                if(newVal !== oldVal) {
                    this.dataLoaded = false
                    this.$nextTick(()=>{
                        this.dataLoaded = true
                    })
                }
            },
        },
        async mounted(){
            this.dataLoaded=true
        },
        methods: {
            loaded: function(args) {
            },
            annotationRender: function(id, sliderValue) {
                let circulargauge = this.$refs["circulargauge_"+this.name].ej2Instances;
                circulargauge['isProtectedOnChange'] = true;
                if (sliderValue >= 0 && sliderValue < 20) {
                    circulargauge.axes[0].pointers[0].color = '#ea501a';
                } else if (sliderValue >= 20 && sliderValue < 40) {
                    circulargauge.axes[0].pointers[0].color = '#f79c02';
                } else if (sliderValue >= 40 && sliderValue < 60) {
                    circulargauge.axes[0].pointers[0].color = '#e5ce20';
                } else if (sliderValue >= 60 && sliderValue < 80) {
                    circulargauge.axes[0].pointers[0].color = '#a1cb43';
                } else if (sliderValue >= 80 && sliderValue < 100) {
                    circulargauge.axes[0].pointers[0].color = '#82b944';
                }
                circulargauge.setPointerValue(0, 0, sliderValue);
            },
        }
    };
    </script>
    <style>    
    #range-container_Axis_0_Annotation_1 .e-control-wrapper.e-slider-container.e-horizontal .e-slider-track {
        background: linear-gradient(to right, #ea501a 0, #ea501a 20%, #f79c02 40%, #e5ce20 60%, #a1cb43 80%, #82b944 100%);
        background: -moz-linear-gradient(to right, #ea501a 0, #ea501a 20%, #f79c02 40%, #e5ce20 60%, #a1cb43 80%, #82b944 100%); 
        background: -webkit-linear-gradient(to right, #ea501a 0, #ea501a 20%, #f79c02 40%, #e5ce20 60%, #a1cb43 80%, #82b944 100%);
    }
    #arc-gauge .e-limit-bar.e-limits {
        background-color: transparent !important;
    }
   
    </style>