<template lang="pug">
div#dashboard
        .d-flex.flex-column
            .content-header.d-flex.justify-content-between.align-items-center.p-1
                h2.content-header-title.m-0.pr-1.mr-1.d-flex
                    span.material-icons-outlined.cursor-pointer(@click="setHomePage()" :class="currentHomePage ? 'text-primary' : ''" style="color: #ccc;") home
                    span.d-block.d-md-none Immobilier
                    span.d-none.d-md-block Tableau de bord > Immobilier 
                .content-header-search-filter.mr-1.d-none.d-md-block(style="flex: 1;")
                    .position-relative.w-100.d-flex.align-items-center
                        .d-none.d-lg-block.mr-1
                            DateRanger(@setDates="setDates" :dateRangeDefault="dateRangeDefault" placeholder="Filtrer par période")
                        .content-header-actions.mr-1
                            .e-btn-group.e-lib.e-css(role='group') 
                                input#viewDay(type='radio' name='viewSlot' value="day" v-model="viewSlot")
                                label.e-btn.e-outline(for='viewDay') Jour
                                input#viewMonth(type='radio' name='viewSlot' value="month" v-model="viewSlot")
                                label.e-btn.e-outline.border-primary(for='viewMonth') Mois
                        ejs-multiselect(cssClass="d-none d-lg-block e-fixe-height" id='institutionsList' name="Société" placeholder='Filtrer par société' popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="institutionsList" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'name', value: 'id' }" v-model='filterInstitutions')
                        //- ejs-multiselect(cssClass="d-none d-lg-block e-fixe-height" id='affairsList' name="Affaires" placeholder='Filtrer par affaire' popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="affairsList" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'displayLabel', value: 'id' }" v-model='filterAffairs')
                .content-header-search.d-block.d-md-none
                .content-header-actions.mr-1
                    .e-btn-group.e-lib.e-css(role='group') 
                        input#viewTypeQte(type='radio' name='viewType' value="sum" v-model="viewType")
                        label.e-btn.e-outline(for='viewTypeQte') Qté
                        input#viewTypeAmount(type='radio' name='viewType' value="amount" v-model="viewType")
                        label.e-btn.e-outline.border-primary(for='viewTypeAmount') Montant
                .content-header-actions
                    .e-btn-group.e-lib.e-css.mr-1(role='group') 
                        input#viewCounted(type='radio' name='viewMode' value="counted" v-model="viewMode")
                        label.e-btn.e-outline(for='viewCounted') Réél
                        input#viewCompare(type='radio' name='viewMode' value="compare" v-model="viewMode" v-if="checkModule(6)")
                        label.e-btn.e-outline.border-primary(for='viewCompare') Réél vs Prévu
                        input#viewForecast(type='radio' name='viewMode' value="forecast" v-model="viewMode" v-if="checkModule(6)")
                        label.e-btn.e-outline(for='viewForecast') Prévu
                    ejs-dropdownbutton( cssClass="e-outline" :select="selectParamerterAction" :items="parametersActionList" iconCss="e-drpdwnbtn-icons e-btn-sb-icons e-icon-field-settings"  :isPrimary="true" )
            b-col(cols='12').d-lg-none 
                .w-100.mb-50
                    DateRanger(@setDates="setDates" :dateRangeDefault="dateRangeDefault" placeholder="Filtrer par période") 
                ejs-multiselect(id='affairCollaborators' cssClass='w-100 e-fixe-height' name="Affaires" placeholder='Filtrer par affaire' popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="affairsList" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'displayLabel', value: 'id' }" v-model='filterAffairs')    
        .w-100 
            b-row.m-0.control-section.dashboard-default
                b-col(md="12")
                    .dashboard-default(v-if="dashboardLoaded")
                        ejs-dashboardlayout#defaultLayout(ref="DashbordInstance"  :enableDock='enableDock' :dockSize='dockSize'  :cellSpacing='spacing' :columns='12' :allowResizing="true" :mediaQuery="'max-width:992px'" @change="onChangeDashboard" @resizeStop="onChangeDashboard" @resizeStart="onChangeResizeStart" @created="onCreatedDashboard")
                            .e-panel(v-for="(panel, indexPanel) of panels" :key="indexPanel" :id="panel.id" :data-row='panel.row' :data-col='panel.col' :data-sizeX='panel.sizeX' :data-sizeY='panel.sizeY' :data-minSizeX='panel.minSizeX' :data-minSizeY='panel.minSizeY')
                                .e-panel-container.p-1(v-if="panel.id=='leases'")
                                    span.material-icons.ml-25.btn-close(@click="onCloseIconHandler(panel.id)") close
                                    .d-flex.justify-content-between.h-100.w-100
                                        div.d-flex.flex-column.align-items-start.h-100
                                            h4.mb-0 Loyers
                                            div.d-flex.align-items-center.h-100(v-if="!dataLoaded" style="flex:1")
                                                b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                            div.d-flex.align-items-center(v-else style="flex:1")
                                                .h0.text-primary {{ viewMode=='forecast' ? ( viewType=='sum' ? countLeases : formatCurrency(amountLeases) ) :  ( viewType=='sum' ? countLeases :  formatCurrency(amountLeases) ) }} 
                                                    //- small(v-if="viewMode=='compare'") / {{ viewType=='sum' ? countLeases : formatCurrency(amountLeases) }}
                                        //- div.d-flex.align-items-center.h-100(v-if="!dataLoaded")
                                        //-     b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                        //- circular-gauge-percent(:ref="'circular-'+panel.id" v-else-if="dataLoaded && panel.loaded" :height="panel.height" :name="panel.id" :data="{percent:viewType=='sum' ? percentQuotesSentGoals : percentAmountQuotesSentGoals}") 
                                .e-panel-container.p-1(v-if="panel.id=='tree-map-leases'")
                                    .d-flex.flex-column.justify-content-between.h-100.w-100
                                        div.d-flex.w-100
                                            span.material-icons.ml-25.btn-close(@click="onCloseIconHandler(panel.id)") close
                                            h4.d-flex.w-100.mb-50 Répartition par baux
                                        div.text-center.flex-center.h-100(v-if="!dataLoaded" style="flex: 1;")
                                            b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                        treeMapLeases(v-else-if="dataLoaded && panel.loaded" :name="panel.id" :viewType="viewType" :viewMode="viewMode" :data="dataLeasesFiltered" style="flex: 1;")
                                .e-panel-container.p-1(v-if="panel.id=='chart-ranking-institutions'")
                                    .d-flex.flex-column.justify-content-between.h-100.w-100
                                        div.d-flex.w-100
                                            span.material-icons.ml-25.btn-close(@click="onCloseIconHandler(panel.id)") close
                                            h4.d-flex.w-100.mb-50 Classement par société
                                        div.text-center.flex-center.h-100(v-if="!dataLoaded" style="flex: 1;")
                                            b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                        chartRankingInstitutions(v-else-if="dataLoaded && panel.loaded" :name="panel.id" :viewType="viewType" :viewMode="viewMode" :data="dataInstitutionsFiltered" style="flex: 1;")
                                .e-panel-container.p-1(v-else-if="panel.id=='global-chart-leases'")  
                                    h4.mb-0.position-absolute Statistiques par période
                                    span.material-icons.ml-25.btn-close(@click="onCloseIconHandler(panel.id)") close
                                    div.text-center.flex-center.h-100(v-if="!dataLoaded")
                                        b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                    globalChartLeases(v-else-if="dataLoaded && panel.loaded" :viewType="viewType" :viewMode="viewMode" :data="dataGlobal")
                                .e-panel-container.p-1(v-else-if="panel.id=='pie-chart-leases-by-types'")
                                    h4.mb-0.position-absolute Répartition par type
                                    span.material-icons.ml-25.btn-close(@click="onCloseIconHandler(panel.id)") close
                                    div.text-center.flex-center.h-100(v-if="!dataLoaded")
                                        b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                    pie-chart-leases-by-types(v-else-if="dataLoaded && panel.loaded"  :ref="'pie-chart-'+panel.id" :height="panel.height" :name="panel.id" :data="dataLeasesByTypesFiltered")  
    
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelsDirective,
} from "@syncfusion/ej2-vue-layouts";

import {
  formatNumber,
  formatCurrency,
  replaceNbsps,
} from "@/types/api-orisis/library/FormatOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import DateRanger from "@/components/global/date-ranger/DateRanger.vue";
import TreeMapLeases from "@/components/dashboard/baux/TreeMapLeases";
import ChartRankingInstitutions from "@/components/dashboard/baux/ChartRankingInstitutions";
import GlobalChartLeases from "@/components/dashboard/baux/GlobalChartLeases";
import PieChartLeasesByTypes from "@/components/dashboard/baux/PieChartLeasesByTypes";

import CircularGaugePercent from "@/components/dashboard/crm/CircularGaugePercent";

export default {
  components: {
    "ejs-dashboardlayout": DashboardLayoutComponent,
    "e-panel": PanelDirective,
    "e-panels": PanelsDirective,
    DateRanger,
    TreeMapLeases,
    ChartRankingInstitutions,
    GlobalChartLeases,
    PieChartLeasesByTypes,

    CircularGaugePercent,
  },
  data() {
    return {
      currentHomePage: false,
      title: "Synthèse",
      name: "dashboardImmo",
      tabActive: "all",
      selectedRows: [],
      dateRangeDefault: [
        new Date(dayjs().startOf("month").format("YYYY-MM-DD")),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")),
      ],
      dateRange: {
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      },
      filterInstitutions: [],
      filterAffairs: [],

      dataPreLoaded: false,
      dataLoaded: false,

      viewMode: "compare",
      viewType: "sum",
      viewSlot: "month",

      dashboardLoaded: false,
      count: 8,
      spacing: [10, 10],
      aspectRatio: 100 / 85,
      enableDock: true,
      dockSize: "60px",
      panelsDefault: [
        {
          id: "leases",
          row: 0,
          col: 0,
          sizeX: 3,
          sizeY: 1,
        },
        {
          id: "pie-chart-leases-by-types",
          row: 1,
          col: 0,
          sizeX: 3,
          sizeY: 3,
          minSizeX: 2,
          minSizeY: 2,
        },
        {
          id: "tree-map-leases",
          row: 4,
          col: 0,
          sizeX: 6,
          sizeY: 4,
        },
        {
          id: "chart-ranking-institutions",
          row: 4,
          col: 6,
          sizeX: 6,
          sizeY: 4,
        },
        {
          id: "global-chart-leases",
          row: 0,
          col: 3,
          sizeX: 9,
          sizeY: 4,
        },
      ],
      panels: [],
      parametersActionList: [
        {
          text: "Réinitiliser mon tableau de bord",
          iconCss: "e-btn-sb-icons e-icons e-reset",
          type: "dashboardImmo_panelsDefault",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "institutionsList",
      "leasesListByWorkspace",

      "activitiesList",
      "invoicesList",
      "quotesList",
      "orderFormCustomersList",
      "collaboratorsList",
      "isLoadingCollaboratorsList",
      "affairsList",
      "isLoadingQuotesList",
      "isLoadingInvoicesList",
      "workspaceSelected",
      "userParametersList",
      "userCollaboratorId",
      "userCollaborator",
    ]),
    statisticsDurationInYears() {
      return dayjs(this.dateRange.endDate).diff(
        dayjs(this.dateRange.startDate),
        "years",
        true
      );
    },
    statisticsDurationInMonths() {
      return dayjs(this.dateRange.endDate).diff(
        dayjs(this.dateRange.startDate),
        "months",
        true
      );
    },
    statisticsDurationInDays() {
      return dayjs(this.dateRange.endDate).diff(
        dayjs(this.dateRange.startDate),
        "days",
        true
      );
    },

    dataInstitutionsFiltered() {
      let dataInstitutionsMapped = [];
      dataInstitutionsMapped = this.dataLeasesFiltered.reduce((acc, obj) => {
        // On crée un object par affaire
        let findIndex = acc.findIndex(
          (el) => el.institutionId == obj.institutionId
        );
        if (findIndex < 0) {
          // On instancie
          acc.push({
            ...obj,
            institutionId: obj.institutionId,
            institutionLabel: this.institutionsList.find(
              (el) => el.id == obj.institutionId
            )
              ? this.institutionsList.find((el) => el.id == obj.institutionId)
                  .name
              : "Société non identifiée",
            totalRentPeriode: obj.statisticsRentPeriode,
            countLeasePeriod: 1,
            displayText: "1 bail",
          });
        } else {
          // On additionne
          acc[findIndex]["totalRentPeriode"] += obj.statisticsRentPeriode;
          acc[findIndex]["countLeasePeriod"]++;
          acc[findIndex]["displayText"] =
            acc[findIndex]["countLeasePeriod"] > 1
              ? acc[findIndex]["countLeasePeriod"] + " baux"
              : acc[findIndex]["countLeasePeriod"] + " bail";
        }
        return acc;
      }, []);

      return dataInstitutionsMapped.sort(function (a, b) {
        return a.totalRentPeriode - b.totalRentPeriode;
      });
    },

    dataLeasesFiltered() {
      let leasesPeriod = this.leasesListByWorkspace.filter(
        (lease) =>
          dayjs(lease.startDate).format("YYYY-MM-DD") <=
            dayjs(this.dateRange.endDate).format("YYYY-MM-DD") &&
          dayjs(lease.endDate).format("YYYY-MM-DD") >=
            dayjs(this.dateRange.startDate).format("YYYY-MM-DD")
      );
      for (let index = 0; index < leasesPeriod.length; index++) {
        const lease = leasesPeriod[index];
        if (
          dayjs(lease.startDate).format("YYYY-MM-DD") <
            dayjs(this.dateRange.startDate).format("YYYY-MM-DD") &&
          dayjs(lease.endDate).format("YYYY-MM-DD") >
            dayjs(this.dateRange.endDate).format("YYYY-MM-DD")
        ) {
          // le bail est actif pendant toute la durée des stats
          lease["statisticsDurationDays"] = dayjs(
            dayjs(this.dateRange.endDate).format("YYYY-MM-DD")
          )
            .add(1, "day")
            .diff(
              dayjs(dayjs(this.dateRange.startDate).format("YYYY-MM-DD")),
              "days",
              true
            );
          lease["statisticsDurationPeriode"] = dayjs(
            dayjs(this.dateRange.endDate).format("YYYY-MM-DD")
          )
            .add(1, "day")
            .diff(
              dayjs(dayjs(this.dateRange.startDate).format("YYYY-MM-DD")),
              "years",
              true
            );
        } else if (
          dayjs(lease.startDate).format("YYYY-MM-DD") >=
            dayjs(this.dateRange.startDate).format("YYYY-MM-DD") &&
          dayjs(lease.endDate).format("YYYY-MM-DD") <=
            dayjs(this.dateRange.endDate).format("YYYY-MM-DD")
        ) {
          // Le bail n'est pas actif durant toute la période
          // Nb jour = diff lease.startDate avec lease.endDate
          lease["statisticsDurationDays"] = dayjs(
            dayjs(lease.endDate).format("YYYY-MM-DD")
          )
            .add(1, "day")
            .diff(
              dayjs(dayjs(lease.startDate).format("YYYY-MM-DD")),
              "days",
              true
            );
          lease["statisticsDurationPeriode"] = dayjs(
            dayjs(lease.endDate).format("YYYY-MM-DD")
          )
            .add(1, "day")
            .diff(
              dayjs(dayjs(lease.startDate).format("YYYY-MM-DD")),
              "years",
              true
            );
        } else if (
          dayjs(lease.startDate).format("YYYY-MM-DD") >=
            dayjs(this.dateRange.startDate).format("YYYY-MM-DD") &&
          dayjs(lease.endDate).format("YYYY-MM-DD") >
            dayjs(this.dateRange.endDate).format("YYYY-MM-DD")
        ) {
          // Le bail n'est pas actif durant toute la période
          // Nb jour = diff lease.startDate avec this.dateRange.endDate
          lease["statisticsDurationDays"] = dayjs(
            dayjs(this.dateRange.endDate).format("YYYY-MM-DD")
          )
            .add(1, "day")
            .diff(
              dayjs(dayjs(lease.startDate).format("YYYY-MM-DD")),
              "days",
              true
            );
          lease["statisticsDurationPeriode"] = dayjs(
            dayjs(this.dateRange.endDate).format("YYYY-MM-DD")
          )
            .add(1, "day")
            .diff(
              dayjs(dayjs(lease.startDate).format("YYYY-MM-DD")),
              "years",
              true
            );
        } else if (
          dayjs(lease.startDate).format("YYYY-MM-DD") <
            dayjs(this.dateRange.startDate).format("YYYY-MM-DD") &&
          dayjs(lease.endDate).format("YYYY-MM-DD") <=
            dayjs(this.dateRange.endDate).format("YYYY-MM-DD")
        ) {
          // Le bail n'est pas actif durant toute la période
          // Nb jour = diff  this.dateRange.startDate avec lease.endDate
          lease["statisticsDurationDays"] = dayjs(
            dayjs(lease.endDate).format("YYYY-MM-DD")
          )
            .add(1, "day")
            .diff(
              dayjs(dayjs(this.dateRange.startDate).format("YYYY-MM-DD")),
              "days",
              true
            );
          lease["statisticsDurationPeriode"] = dayjs(
            dayjs(lease.endDate).format("YYYY-MM-DD")
          )
            .add(1, "day")
            .diff(
              dayjs(dayjs(this.dateRange.startDate).format("YYYY-MM-DD")),
              "years",
              true
            );
        }
      }
      leasesPeriod = leasesPeriod.map((lease) => {
        return {
          startDate: dayjs(lease.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(lease.endDate).format("YYYY-MM-DD"),
          institutionId: lease.institutionId,
          institutionLabel: this.institutionsList.find(
            (el) => el.id == lease.institutionId
          )
            ? this.institutionsList.find((el) => el.id == lease.institutionId)
                .name
            : "Société non identifiée",
          leaseTypeId: lease.leaseTypeId,
          leaseType: lease.leaseType,
          tenantLabel: lease.tenantName,
          label: lease.label,
          rentHcHt: lease.rentHcHt,
          statisticsDurationDays: lease.statisticsDurationDays,
          statisticsDurationPeriode: lease.statisticsDurationPeriode,
          statisticsRentPeriode:
            lease.statisticsDurationPeriode * lease.rentHcHt,
          displayText_statisticsRentPeriode: this.replaceNbsps(
            this.formatCurrency(
              lease.statisticsDurationPeriode * lease.rentHcHt
            )
          ),
          periodicity: lease.periodicity,
        };
      });

      if (this.filterInstitutions.length > 0) {
        return leasesPeriod.filter((el) =>
          this.filterInstitutions.includes(el.institutionId)
        );
      } else {
        return leasesPeriod;
      }
    },

    amountLeases() {
      let sum = 0;
      for (let i = 0; i < this.dataLeasesFiltered.length; i++) {
        sum += this.dataLeasesFiltered[i].statisticsRentPeriode;
      }
      return sum;
    },
    countLeases() {
      return this.dataLeasesFiltered.length;
    },

    dataGlobal() {
      let dataGlobalMapped = [];

      let dateDebut = new Date(this.dateRange.startDate);
      let dateFin = new Date(this.dateRange.endDate);
      let dateActuelle = new Date(dateDebut);

      while (dateActuelle <= dateFin) {
        let dateStr = dayjs(dateActuelle).startOf("month").format("MMM YY");
        let totalLeases = 0;
        let amountLeases = 0;
        this.dataLeasesFiltered
          .filter(
            (lease) =>
              dayjs(lease.startDate).format("YYYY-MM-DD") <=
                dayjs(dateActuelle).endOf("month").format("YYYY-MM-DD") &&
              dayjs(lease.endDate).format("YYYY-MM-DD") >=
                dayjs(dateActuelle).startOf("month").format("YYYY-MM-DD")
          )
          .forEach((leases) => {
            if (
              leases.startDate <
                dayjs(dateActuelle).startOf("month").format("YYYY-MM-DD") &&
              leases.endDate >
                dayjs(dateActuelle).endOf("month").format("YYYY-MM-DD")
            ) {
              // le bail est actif pendant toute la durée des stats
              leases["statisticsDurationDays"] = dayjs(dateActuelle)
                .endOf("month")
                .add(1, "day")
                .diff(dayjs(dateActuelle).startOf("month"), "days", true);
              leases["statisticsDurationPeriode"] = dayjs(dateActuelle)
                .endOf("month")
                .add(1, "day")
                .diff(dayjs(dateActuelle).startOf("month"), "years", true);
            } else if (
              leases.startDate >=
                dayjs(dateActuelle).startOf("month").format("YYYY-MM-DD") &&
              leases.endDate <=
                dayjs(dateActuelle).endOf("month").format("YYYY-MM-DD")
            ) {
              // Le bail n'est pas actif durant toute la période
              // Nb jour = diff leases.startDate avec leases.endDate
              leases["statisticsDurationDays"] = dayjs(leases.endDate)
                .add(1, "day")
                .diff(dayjs(leases.startDate), "days", true);
              leases["statisticsDurationPeriode"] = dayjs(leases.endDate)
                .add(1, "day")
                .diff(dayjs(leases.startDate), "years", true);
            } else if (
              leases.startDate >=
                dayjs(dateActuelle).startOf("month").format("YYYY-MM-DD") &&
              leases.endDate >
                dayjs(dateActuelle).endOf("month").format("YYYY-MM-DD")
            ) {
              // Le bail n'est pas actif durant toute la période
              // Nb jour = diff leases.startDate avec this.dateRange.endDate
              leases["statisticsDurationDays"] = dayjs(dateActuelle)
                .endOf("month")
                .add(1, "day")
                .diff(dayjs(leases.startDate), "days", true);
              leases["statisticsDurationPeriode"] = dayjs(dateActuelle)
                .endOf("month")
                .add(1, "day")
                .diff(dayjs(leases.startDate), "years", true);
            } else if (
              leases.startDate <
                dayjs(dateActuelle).startOf("month").format("YYYY-MM-DD") &&
              leases.endDate <=
                dayjs(dateActuelle).endOf("month").format("YYYY-MM-DD")
            ) {
              // Le bail n'est pas actif durant toute la période
              // Nb jour = diff  this.dateRange.startDate avec leases.endDate
              leases["statisticsDurationDays"] = dayjs(leases.endDate)
                .add(1, "day")
                .diff(dayjs(dateActuelle).startOf("month"), "days", true);
              leases["statisticsDurationPeriode"] = dayjs(leases.endDate)
                .add(1, "day")
                .diff(dayjs(dateActuelle).startOf("month"), "years", true);
            }
            totalLeases++;
            amountLeases += leases.statisticsDurationPeriode * leases.rentHcHt;
          });
        // let amountInvoicesSent = 0
        // let totalInvoicesSent = 0
        // this.dataInvoicesFiltered.filter(invoice => [2,6,7].includes(invoice.status) && (this.viewSlot=="month" ? dayjs(dateActuelle).format('YYYY-MM') == dayjs(invoice.documentDate).format('YYYY-MM') : dayjs(dateActuelle).format('YYYY-MM-DD') == dayjs(invoice.documentDate).format('YYYY-MM-DD')) ).forEach(invoices => {
        //     totalInvoicesSent ++
        //     amountInvoicesSent += invoices.totalHt;
        // });

        dataGlobalMapped.push({
          date:
            this.viewSlot == "month"
              ? dayjs(dateActuelle).format("YYYY-MM")
              : dayjs(dateActuelle).format("YYYY-MM-DD"),
          dateStr: dateStr.toUpperCase(),

          totalLeases: totalLeases,
          totalLeasesDisplayText:
            totalLeases > 1 ? totalLeases + " baux" : totalLeases + " bail",
          colorLeases: "#6A7EC3",
          amountLeases: amountLeases.toFixed(2),
          amountLeasesDisplayText: this.formatCurrency(amountLeases.toFixed(2)),
        });

        if (this.viewSlot == "month") {
          if (dateActuelle.getMonth() === 11) {
            dateActuelle.setFullYear(dateActuelle.getFullYear() + 1);
            dateActuelle.setMonth(0);
          } else {
            dateActuelle.setMonth(dateActuelle.getMonth() + 1);
          }
        } else {
          dateActuelle.setDate(dateActuelle.getDate() + 1);
        }
      }
      return dataGlobalMapped;
    },

    dataLeasesByTypesFiltered() {
      let dataLeasesByTypesMapped = [];
      dataLeasesByTypesMapped = this.dataLeasesFiltered.reduce((acc, obj) => {
        // On crée un object par affaire
        let findIndex = acc.findIndex(
          (el) => el.leaseTypeId == obj.leaseTypeId
        );
        if (findIndex < 0) {
          // On instancie
          acc.push({
            leaseTypeId: obj.leaseTypeId,
            leaseTypeLabel: obj.leaseType ? obj.leaseType.label : "Non défini",
            institutionId: obj.institutionId,
            institutionLabel: this.institutionsList.find(
              (el) => el.id == obj.institutionId
            )
              ? this.institutionsList.find((el) => el.id == obj.institutionId)
                  .name
              : "Société non identifiée",
            totalRentPeriode: obj.statisticsRentPeriode,
            countLeasePeriod: 1,
            displayText: "1 bail",
          });
        } else {
          // On additionne
          acc[findIndex]["totalRentPeriode"] += obj.statisticsRentPeriode;
          acc[findIndex]["countLeasePeriod"]++;
          acc[findIndex]["displayText"] =
            acc[findIndex]["countLeasePeriod"] > 1
              ? acc[findIndex]["countLeasePeriod"] + " baux"
              : acc[findIndex]["countLeasePeriod"] + " bail";
        }
        return acc;
      }, []);

      return dataLeasesByTypesMapped.map((activity) => {
        return {
          ...activity,
          x: activity.leaseTypeLabel,
          y: activity.countLeasePeriod,
          text: activity.leaseTypeLabel,
          fill: "#EBEA00",
        };
      });
    },
  },
  watch: {
    $route: {
      handler: async function (to, from) {
        this.dataLoaded = false;
        if (to.name == "dashboard-immo") {
          await this.initDashboard();
        }
      },
      deep: true,
      immediate: false,
    },
  },
  async created() {
    this.getCompanies({});
    this.getCollaborators({});

    if (
      this.userParametersList.find((el) => el.key == "homePage") &&
      this.userParametersList.find((el) => el.key == "homePage").value ==
        this.$route.path
    ) {
      this.currentHomePage = true;
    } else {
      this.currentHomePage = false;
    }
    if (
      this.userParametersList.find((el) => el.key == "dashboardImmo_panels") &&
      this.userParametersList.find((el) => el.key == "dashboardImmo_panels")
    ) {
      this.panels = JSON.parse(
        this.userParametersList.find((el) => el.key == "dashboardImmo_panels")
          .value
      );
    } else {
      this.panels = this.panelsDefault;
    }
    this.dashboardLoaded = true;

    await this.initDashboard();
  },
  methods: {
    ...mapActions([
      "createOrUpdateUserParameter",
      "getInvoices",
      "getLeases",
      "getCompanies",
      "getCollaborators",
    ]),
    formatNumber,
    formatCurrency,
    replaceNbsps,
    setHomePage() {
      this.createOrUpdateUserParameter({
        label: "Page d'accueil",
        key: "homePage",
        value: this.$route.path,
      });
      this.currentHomePage = true;
    },
    checkModule(moduleId) {
      return true;
    },
    async initDashboard() {
      if (
        this.userParametersList.find((el) => el.key == "dashboardImmoFilters")
      ) {
        await this.setDates(
          JSON.parse(
            this.userParametersList.find(
              (el) => el.key == "dashboardImmoFilters"
            ).value
          )
        );
      } else {
        await this.setDates({
          startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
        });
      }
    },
    async setDates(dates) {
      if (dates.startDate) {
        this.dataLoaded = false;
        this.dataPreLoaded = false;

        this.dateRange = {
          startDate: new Date(dayjs(dates.startDate).format("YYYY-MM-DD")),
          endDate: dates.endDate
            ? new Date(dayjs(dates.endDate).format("YYYY-MM-DD"))
            : new Date(dayjs().format("YYYY-MM-DD")),
        };
        this.dateRangeDefault = [
          this.dateRange.startDate,
          this.dateRange.endDate,
        ];

        let allPromises = [
          await this.getInvoices({
            dateFrom: this.dateRange.startDate,
            dateTo: this.dateRange.endDate,
          }),
          await this.getLeases({}),
        ];
        Promise.all(allPromises).then(() => {
          this.dataPreLoaded = true;
          this.dataLoaded = true;
        });
      }
    },

    selectParamerterAction(args) {
      if (args.item.type == "dashboardImmo_panels") {
        this.createOrUpdateUserParameter({
          label: "Paramètres de mon tableau de bord immo",
          key: "dashboardImmo_panels",
          value: JSON.stringify(this.panels),
        });
      } else if (args.item.type == "dashboardImmo_panelsDefault") {
        this.createOrUpdateUserParameter({
          label: "Paramètres de mon tableau de bord immo",
          key: "dashboardImmo_panels",
          value: JSON.stringify(this.panelsDefault),
        });
        this.dashboardLoaded = false;
        this.panels = JSON.parse(JSON.stringify(this.panelsDefault));
        this.$nextTick(() => {
          this.dashboardLoaded = true;
        });
        this.$forceUpdate();
      }
    },
    onChangeResizeStart(args) {
      if (this.$refs.DashbordInstance.serialize() !== this.panelsDefault) {
        this.createOrUpdateUserParameter({
          label: "Paramètres de mon tableau de bord immo",
          key: "dashboardImmo_panels",
          value: JSON.stringify(this.$refs.DashbordInstance.serialize()),
        });
      }
      this.panels = this.$refs.DashbordInstance.serialize();
      this.updatePanelSize(false);
    },
    onChangeDashboard(args) {
      if (this.$refs.DashbordInstance.serialize() !== this.panelsDefault) {
        this.createOrUpdateUserParameter({
          label: "Paramètres de mon tableau de bord immo",
          key: "dashboardImmo_panels",
          value: JSON.stringify(this.$refs.DashbordInstance.serialize()),
        });
      }
      this.panels = this.$refs.DashbordInstance.serialize();
      this.updatePanelSize();
    },
    onCreatedDashboard(args) {
      this.updatePanelSize();
    },
    updatePanelSize(loaded = true) {
      var panelElement = document.querySelectorAll(".e-panel");
      for (var i = 0; i < panelElement.length; i++) {
        this.panels.find((el) => el.id == panelElement[i].id).loaded = false;
        this.panels.find((el) => el.id == panelElement[i].id).height =
          panelElement[i].offsetHeight;
        if (loaded) {
          this.panels.find((el) => el.id == panelElement[i].id).loaded = true;
        }
      }
      this.$forceUpdate();
    },
    onCloseIconHandler(panelId) {
      this.$refs.DashbordInstance.removePanel(panelId);
    },
  },
};
</script>
<style lang="scss">
@import "~@syncfusion/ej2-base/styles/material.css";
@import "~@syncfusion/ej2-vue-layouts/styles/material.css";
/* @import "../node_modules/@syncfusion/ej2-base/styles/bootstrap5.css";
    @import "../node_modules/@syncfusion/ej2-vue-layouts/styles/bootstrap5.css"; */

.e-panel-container {
  .btn-close {
    display: none;
    position: absolute;
    cursor: pointer;
    right: 0px;
    z-index: 2;
  }
  &:hover {
    .btn-close {
      display: block;
      right: 1rem;
    }
  }
}

/* DashboardLayout element styles  */
// .dashboard-default #defaultLayout.e-dashboardlayout.e-control .e-panel:hover span {
//     display: block;
// }

.e-dashboardlayout.e-control .e-panel {
  border-radius: 5px;
}

.dashboard-default #defaultLayout .e-panel .e-panel-container .text-align {
  vertical-align: middle;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.dashboard-default .e-template-icon {
  padding: 10px;
  float: right;
  display: none;
}

.dashboard-default .e-clear-icon::before {
  content: "\e7a7";
  font-size: 12px;
  font-family: "e-icons";
}
.bootstrap5 .dashboard-default .e-clear-icon::before,
.bootstrap5-dark .dashboard-default .e-clear-icon::before {
  content: "\e7e7";
  font-size: 16px;
}

.dashboard-default .text-align {
  line-height: 160px;
}

.dashboard-default .e-clear-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}

// .dashboard-default #defaultLayout.e-dashboardlayout.e-control .e-panel:hover span {
//     display: block;
// }

.dashboard-default #defaultLayout .e-panel .e-panel-container .text-align {
  vertical-align: middle;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.dashboard-default .e-template-icon {
  padding: 10px;
  float: right;
  display: none;
}

.dashboard-default .e-clear-icon::before {
  content: "\e7a7";
  font-size: 12px;
  font-family: "e-icons";
}

.bootstrap5 .dashboard-default .e-clear-icon::before,
.bootstrap5-dark .dashboard-default .e-clear-icon::before {
  content: "\e7e7";
  font-size: 16px;
}

.dashboard-default .text-align {
  line-height: 160px;
}

.dashboard-default .e-clear-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}
</style>
