<template lang="pug">
.h-100(v-if="dataLoaded") 
    ejs-accumulationchart(:ref="name" :title='title' height='100%'  :theme="'Bootstrap5'" :legendSettings='legendSettings' :tooltip='tooltip' enableSmartLables='true' :enableAnimation='enableAnimation' :center='center')
        e-accumulation-series-collection
            e-accumulation-series(:dataSource='data' :palettes='palettes' :startAngle='startAngle' :endAngle='endAngle' :radius='radius' xName='x' yName='y' :dataLabel='dataLabel' innerRadius='20%')  
</template>

<script>
import { mapGetters, mapActions } from "vuex";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationLegend,
  PieSeries,
  AccumulationDataLabel,
  AccumulationTooltip,
  AccumulationSelection,
  Selection,
  ChartAnnotation
} from "@syncfusion/ej2-vue-charts";
import { Browser } from '@syncfusion/ej2-base';

export default {
    components: {
        'ejs-accumulationchart': AccumulationChartComponent,
        'e-accumulation-series-collection': AccumulationSeriesCollectionDirective,
        'e-accumulation-series': AccumulationSeriesDirective
    },
    provide: {
        accumulationchart: [AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip, AccumulationSelection, Selection, ChartAnnotation]
    },
    props:{
        data:{
            type:Array,
            default: () => []
        },
        viewMode:{
            type:String,
            default:"compare"
        }
    },
    data() {
        return {
            title: "",
            name: "pieChartActivitiesByChanelTypes",
            dataLoaded:false,
            palettes: ['#0C3571','#3F5899','#6A7EC3','#94A6EF','#BFD0FF'],
            // palettes: ["#EBEA00", "#F8F0A2", "#FCF5C4", "#FFFAE6", "#F2ED51"],
            dataLabel: {
                visible: true,
                position: 'Outside', name: 'text',
                font: {
                    fontWeight: '500',
                    color: '#5e5873'
                }
            },
            enableSmartLabels: true,
            enableAnimation: false,
            legendSettings: {
                visible: false,
            },
            tooltip: { enable: true, format: '${point.x} : <b>${point.y}</b>' },
            startAngle: '0',
            endAngle: '360',
            radius: '70%',
            explodeOffset: '10%',
            explodeIndex : 0,
            center: {x: '50%', y: '50%'},
        }
    },
    watch : {
        data: function (newVal, oldVal) {
            if(newVal !== oldVal) {
                this.dataLoaded = false
                this.$nextTick(()=>{
                    this.dataLoaded = true
                })
            }
        },
    },
    async mounted(){
        this.dataLoaded=true
    },
};
</script>