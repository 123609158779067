<template lang="pug">
ejs-chart(:ref="name" height="100%" :key="key" theme='bootstrap5' align='center'  :primaryXAxis='primaryXAxis' :primaryYAxis='primaryYAxis' :chartArea='chartArea' :width='width' :legendSettings='legendSettings' :tooltip='tooltip' style="display:block; margin-top:-1rem")
    e-series-collection
        e-series(:dataSource='data' v-if="viewType == 'sum'" fill="#0C3571" type='Column' xName='date' yName='totalLeases' name='Baux'  :cornerRadius="cornerRadius" groupName='totalLeases' columnWidth='.5' opacity='1' columnSpacing='0' tooltipMappingName='totalLeasesDisplayText' :border="{color: '#0C3571', width: 4 }") 
        e-series(:dataSource='data' v-else fill="#0C3571" type='Column' xName='date' yName='amountLeases' name='Baux'  :cornerRadius="cornerRadius" groupName='amountLeases' columnWidth='.5' opacity='1' columnSpacing='0' tooltipMappingName='amountLeasesDisplayText' :border="{color: '#0C3571', width: 4 }")  
        //- e-series(:dataSource='data' v-if="viewType == 'sum'" fill="#0C3571" type='Column' xName='date' yName='totalInvoicesSent' name='Factures émises'  :cornerRadius="cornerRadius" groupName='totalInvoicesSent' columnWidth='.5' opacity='1' columnSpacing='0' tooltipMappingName='totalInvoicesSentDisplayText' :border="{color: '#0C3571', width: 4 }") 
        //- e-series(:dataSource='data' v-else fill="#0C3571" type='Column' xName='date' yName='amountInvoicesSent' name='Factures émises'  :cornerRadius="cornerRadius" groupName='amountInvoicesSent' columnWidth='.5' opacity='1' columnSpacing='0' tooltipMappingName='amountInvoicesSentDisplayText' :border="{color: '#0C3571', width: 4 }") 
</template>
<script>
import { LineSeries, StackingColumnSeries, Tooltip, ColumnSeries, Category, Legend, DataLabel, Highlight, ChartAnnotation } from "@syncfusion/ej2-vue-charts";



export default {
    props:{
        data:{
            type:Array,
            default: () => []
        },
        viewType:{
            type:String,
            default:"amount"
        },
        viewMode:{
            type:String,
            default:"compare"
        }
    },
    data() {
        return {
            title: "Synthèse analytique des activités",
            name: "globalChartCRM",
            dataLoaded:false,
            key:1,

            //Initializing Primary X Axis
            primaryXAxis: {
                title: '',
                labelIntersectAction: 'Rotate45',
                valueType: 'Category',
                majorTickLines: { width: 0 },
            },

            primaryYAxis:{
                // title: '',
                // lineStyle: { width: 0 },
                // majorTickLines: { width: 0 },
                // labelFormat: '{value} €',
                // lineStyle: { width: 0 },
                // majorTickLines: { width: 0 },
                // majorGridLines: { color: '#eaeaea', width: 1 }
            },
            chartArea: {
                border: {
                    width: 0
                }
            },
            circleMarkerForecast: { 
                visible: true, height: 5, width: 5 , shape: 'Circle' ,
                fill: '#ffffff',
                border:{width: 2, color: '#ff9f43'} ,
            },
            circleMarkerCounted: { 
                visible: true, height: 5, width: 5 , shape: 'Circle' ,
                fill: '#ffffff',
                border:{width: 2, color: '#0c3571'} ,
            },
            cornerRadius:{
                bottomLeft:0,
                bottomRight:0,
                topLeft:4,
                topRight:4,
            },
            markerForecastOutput: {
                visible: true,
                shape: "Rectangle",
                isFilled: true,
                border:{width: 2, color: '#ffffff'} ,
                height: 3, 
                width: 44,
                label:"test",
                dataLabel:{
                    alignment:"center",
                    border:{width: 2, color: '#0c3571'} ,
                    fill: "#0c3571",
                    visible:true
                }
            },
            markerForecastInput: {
                visible: true,
                shape: "Rectangle",
                isFilled: true,
                border:{width: 2, color: '#ffffff'} ,
                height: 3,
                width: 44,
            },
            border: {
                color: 'black',
                width: 2
            },
            width: '100%',
            legendSettings: {
                visible: true,
                enableHighlight: false,
                position: "Top",
                alignment: "Far"
            },
            tooltip: { 
                enable: true, 
                header: '${point.x}', 
                format: '${series.name} : <b>${point.tooltip}</b>'
            },
            palettes: ["#dc3545", "#dc3545", "#28a745", "#28a745", "#0c3571", "#ff9f43"],

        };
    },
    watch : {
        data: function (newVal, oldVal) {
            if(newVal !== oldVal) {
                this.dataLoaded = false
                this.$nextTick(()=>{
                    this.dataLoaded = true
                })
            }
        },
    },
    async mounted(){
        this.dataLoaded=true
    },
    provide: {
        chart: [StackingColumnSeries, LineSeries, Category, ColumnSeries, Legend, Tooltip, Highlight, DataLabel, ChartAnnotation]
    },
};
</script>